import React from "react"
import { useCalendly } from "../hooks/useCalendly"

export const Calendly = ({ url, ...props }) => {
  useCalendly()

  return (
    <div
      className="calendly-inline-widget"
      data-url={url}
      {...props}
      style={{ minWidth: "320px", height: "630px", ...props.style }}
    ></div>
  )
}
