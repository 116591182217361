import { graphql } from "gatsby"
import React from "react"

import TestDrivePage from "../components/testdrive"

const TestdrivePage: React.FC<{ data: unknown }> = ({ data }) => {
  const {
    useCaseName,
    backgroundImage,
    mavenoidProductId,
    mavenoidOrgName,
    mavenoidClientId,
    shortcuts,
    widgets,
    mavenoidModelExport: {
      file: { url: mavenoidModelExportFileUrl },
    },
    // @ts-expect-error untyped `data`
  } = data.contentfulTestDrivePage

  return (
    <TestDrivePage
      useCaseName={useCaseName}
      backgroundImage={backgroundImage}
      mavenoidProductId={mavenoidProductId}
      mavenoidOrgName={mavenoidOrgName}
      mavenoidClientId={mavenoidClientId}
      mavenoidModelExportFileUrl={mavenoidModelExportFileUrl}
      shortcuts={shortcuts}
      widgets={widgets}
    />
  )
}

export default TestdrivePage

export const query = graphql`
  query TestDrivePage($contentfulId: String!) {
    contentfulTestDrivePage(contentful_id: { eq: $contentfulId }) {
      useCaseName
      backgroundImage {
        ...gatsbyImageTracedSVG
      }
      shortcuts {
        contentful_id: id
        name
        thumbnail {
          ...gatsbyImageTracedSVG
        }
        deepLink
      }
      mavenoidProductId
      mavenoidOrgName
      mavenoidClientId
      mavenoidModelExport {
        file {
          url
        }
      }
      widgets {
        ...QuoteWidget
        ...StatsWidget
        ...SideBySideWidget
      }
    }
  }
`
