import styled from "@emotion/styled"
import React from "react"

const Iframe = styled.iframe`
  display: block;
  height: 100%;
  width: 100%;
  border: none;
`

interface Props {
  domain: string
  currentOperationId: string | null
  onPostMessage: (message: unknown) => void
}
const ModelIframe = React.forwardRef<HTMLIFrameElement, Props>(
  ({ domain, currentOperationId, onPostMessage }, ref) => {
    React.useEffect(() => {
      if (currentOperationId)
        onPostMessage({
          type: "mavenoid-highlight-operation",
          operationId: currentOperationId,
          // Not passing any Flow information here because the flow most likely has other IDs than
          // the executable one
        })
    }, [currentOperationId, onPostMessage])
    return (
      <Iframe
        ref={ref}
        title="Mavenoid Behind-The-Hood content"
        frameBorder={0}
        src={`${domain}/embed/canvas`}
      />
    )
  }
)

export default ModelIframe
