import styled from "@emotion/styled"
import React from "react"
import colors from "../../styles/colors"
import { DesktopMediaQuery, MobileMediaQuery } from "../../styles/constants"
import { ContentfulImage } from "../ContentfulImage"
import Typography, { typographyStyles } from "../typography"
import { TestDriveLabel } from "./testDriveLabel"
import { TestdriveShortcut } from "./types"

const TestDriveHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const TestDriveHeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${colors.white};
  margin-top: 170px;
  height: 450px;

  ${MobileMediaQuery} {
    margin-top: 100px;
    height: 550px;
  }
`
const TestDriveHeadline = styled.h1`
  ${typographyStyles.h2}
  margin-bottom: 20px;

  & > strong {
    color: ${colors.primary};
  }
`

const ShortcutsContainer = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 140px;

  ${MobileMediaQuery} {
    flex-wrap: wrap;
  }
`
const ShortcutContainer = styled.button`
  appearance: none;
  all: unset;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  margin-left: -16px;
  padding: 16px;
  width: 180px;
  cursor: pointer;
  border-radius: 8px;

  transition: background-color 150ms ease-in-out;

  ${MobileMediaQuery} {
    width: calc(50% - 32px);
  }

  /* Safari fix */
  img {
    border-radius: 8px;
  }

  &:focus:focus-visible {
    background: rgba(0, 0, 0, 0.1);
  }
`
const ShortcutImageWrapper = styled.div`
  overflow: hidden;
  border-radius: 4px;
  width: 100%;
  height: 120px;
  margin-bottom: 8px;
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;

  ${MobileMediaQuery} {
    height: unset;

    & > div {
      display: block;
    }
  }

  ${ShortcutContainer}:hover & {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
  }
`

const MobileButton = styled.button`
  all: unset;
  align-self: stretch;
  background: ${colors.primary};
  color: ${colors.white};
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  padding: 12px;
  max-width: 330px;
  border-radius: 1000px;
  cursor: pointer;

  ${DesktopMediaQuery} {
    display: none;
  }
`

interface TestdriveHeroProps {
  useCaseName: string
  shortcuts: TestdriveShortcut[]
  onOpenShortcut: (shortcut?: TestdriveShortcut) => void
}
export const TestdriveHero = ({
  useCaseName,
  shortcuts,
  onOpenShortcut,
}: TestdriveHeroProps) => {
  return (
    <TestDriveHeroContainer>
      <TestDriveHeroWrapper>
        <TestDriveLabel style={{ marginBottom: 20 }} />
        <TestDriveHeadline>
          Mavenoid for
          <br />
          <strong>{useCaseName}</strong>
        </TestDriveHeadline>
        <Typography as="p" variant="bodyLarge">
          Experience how Mavenoid is the perfect support platform to help your
          customers onboard, use, and troubleshoot products like yours.
        </Typography>
        <MobileButton onClick={() => onOpenShortcut()}>
          Open the Assistant
        </MobileButton>
      </TestDriveHeroWrapper>

      {shortcuts.length > 0 && (
        <Typography variant="subtitleLarge" style={{ color: colors.white }}>
          Jump to common use cases
        </Typography>
      )}
      <ShortcutsContainer>
        {(shortcuts || []).map(shortcut => (
          <ShortcutContainer
            key={shortcut.id}
            onClick={() => onOpenShortcut(shortcut)}
          >
            <ShortcutImageWrapper>
              <ContentfulImage image={shortcut.thumbnail} />
            </ShortcutImageWrapper>
            <Typography variant="bodySmall">{shortcut.name}</Typography>
          </ShortcutContainer>
        ))}
      </ShortcutsContainer>
    </TestDriveHeroContainer>
  )
}
