import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import colors, { TColor } from "../../styles/colors"
import {
  Illustration as IllustrationProps,
  IWidgetProps,
  WidgetIllustration,
} from "../../types/widget"
import { ContentfulImage } from "../ContentfulImage"
import AspectRatio from "../aspectRatio"
import Typography, { typographyStyles } from "../typography"
import { BORDER_RADIUS_SMALL, MobileMediaQuery } from "../../styles/constants"

const IllustrationsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1.2;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const IllustrationWrapper = styled.div<{ bgColor: TColor }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${BORDER_RADIUS_SMALL}px;

  background-color: ${p => colors[p.bgColor || "primary"]};

  width: calc(50% - 16px);
  margin-bottom: 32px;

  ${MobileMediaQuery} {
    margin-bottom: 16px;
    width: calc(50% - 8px);
  }

  &:nth-child(2n) {
    margin-left: 16px;
    ${MobileMediaQuery} {
      margin-left: 8px;
    }
  }
  &:nth-child(2n + 1) {
    margin-right: 16px;
    ${MobileMediaQuery} {
      margin-right: 8px;
    }
  }
`

const AspectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Image = styled(ContentfulImage)``

const ImageWrapper = styled.div`
  width: 80%;
  height: 50%;
  padding: 1rem 2rem;
`

const TitleWrapper = styled.div``

const Title = styled(Typography)`
  display: flex;
  padding: 0 24px;
  @media screen and (max-width: 650px) {
    padding: 0;
    ${typographyStyles.bodySmall}
  }
`

interface IllustrationItemProps {
  illustration: IllustrationProps
}

const Illustration = ({ illustration }: IllustrationItemProps): JSX.Element => {
  return (
    <IllustrationWrapper bgColor={illustration.backgroundColor}>
      <AspectRatio>
        <AspectContainer>
          <ImageWrapper>
            <Image image={illustration.illustration} />
          </ImageWrapper>

          <TitleWrapper>
            <Title variant="bodyLarge">{illustration.title}</Title>
          </TitleWrapper>
        </AspectContainer>
      </AspectRatio>
    </IllustrationWrapper>
  )
}

export const IllustrationWidget = ({
  widget,
}: IWidgetProps<WidgetIllustration>): JSX.Element => (
  <IllustrationsWrapper>
    {widget.illustrations.map(item => (
      <Illustration illustration={item} />
    ))}
  </IllustrationsWrapper>
)

export const IllustrationFragment = graphql`
  fragment IllustrationWidget on ContentfulIllustrationWidget {
    internalTitle
    illustrations {
      title
      backgroundColor
      illustration {
        ...contentfulImageGatsby
      }
    }
  }
`
