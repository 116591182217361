import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import colors from "../../styles/colors"
import {
  BORDER_RADIUS_MEDIUM,
  DesktopMediaQuery,
  MobileMediaQuery,
} from "../../styles/constants"
import { IWidgetProps, Stats, WidgetStats } from "../../types/widget"
import Card from "../card"
import { ContentfulImage } from "../ContentfulImage"
import Typography, { TypographyMarkdown, typographyStyles } from "../typography"

const StatsWrapper = styled.div`
  display: flex;
  width: 100%;

  ${MobileMediaQuery} {
    flex-direction: column;
    align-items: center;
  }
`

const StatWrapper = styled.div<{
  isNotFirst: boolean
  hasIllustration: boolean
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: ${p => (p.hasIllustration ? 140 : 330)}px;

  padding: 1.75rem;

  ${MobileMediaQuery} {
    flex-direction: row;
    margin-top: ${p => (p.isNotFirst && !p.hasIllustration ? 30 : 0)}px;
    max-width: 380px;
    white-space: nowrap;
  }

  ${DesktopMediaQuery} {
    margin-left: ${p => (p.isNotFirst ? 30 : 0)}px;
  }

  border-radius: ${BORDER_RADIUS_MEDIUM}px;
`

const ImageWrapper = styled.div`
  flex: 1;
  height: 70px;
  margin-bottom: 1rem;
  ${MobileMediaQuery} {
    margin-bottom: 0;
    margin-right: 2rem;
  }
`

const MarkdownWrapper = styled(TypographyMarkdown)<{
  removePPadding: boolean
}>`
  p {
    ${p =>
      p.removePPadding
        ? css`
            margin-top: 0;
            ${typographyStyles.bodySmall}
          `
        : null}
  }
`

interface StatsCardProps extends Stats {
  isNotFirst: boolean
}

const StatsCard = ({
  value,
  text,
  textColor,
  backgroundColor,
  isNotFirst,
  illustration,
}: StatsCardProps): JSX.Element => (
  <StatWrapper
    hasIllustration={Boolean(illustration)}
    style={{
      backgroundColor: colors[backgroundColor],
      color: colors[textColor],
    }}
    isNotFirst={isNotFirst}
  >
    {illustration && (
      <ImageWrapper>
        <ContentfulImage image={illustration} />
      </ImageWrapper>
    )}
    <div style={{ flex: 1 }}>
      <Typography variant={illustration ? "h4" : "h1"}>{value}</Typography>
      <MarkdownWrapper
        removePPadding={Boolean(illustration)}
        dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}
      />
    </div>
  </StatWrapper>
)

export const StatsWidget = ({
  widget,
}: IWidgetProps<WidgetStats>): JSX.Element => {
  return (
    <Card
      variant={widget.backgroundColor ? widget.backgroundColor : "white"}
      noMinHeight
      center
    >
      {!widget.hideTitle && (
        <Typography variant="h3" style={{ marginBottom: "4rem" }}>
          {widget.title}
        </Typography>
      )}
      <StatsWrapper>
        {widget.stats.map((stat, index) => (
          <StatsCard key={stat.value} {...stat} isNotFirst={index !== 0} />
        ))}
      </StatsWrapper>
    </Card>
  )
}

export const StatsWidgetFragment = graphql`
  fragment StatsWidget on ContentfulStatsWidget {
    __typename
    title
    hideTitle
    backgroundColor
    stats {
      value
      text {
        childMarkdownRemark {
          html
        }
      }
      textColor
      backgroundColor
      illustration {
        ...contentfulImageGatsby
      }
    }
  }
`
