import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import { MobileMediaQuery } from "../../styles/constants"
import { IWidgetProps, WidgetSideBySide } from "../../types/widget"
import Card from "../card"
import Typography, { TypographyMarkdown, typographyStyles } from "../typography"
import Img from "gatsby-image"
import { HoverButton } from "../button"
import colors, { TColor } from "../../styles/colors"
import { Calendly } from "../calendly"
import { css } from "@emotion/react"
import Link from "../link"
import { IllustrationWidget } from "./IllustrationWidget"

const Wrapper = styled(Card)`
  height: 768px;
  justify-content: center;

  ${MobileMediaQuery} {
    height: 100%;
  }
`

const FullWidthAlignment = styled.div<{
  hasImage: boolean
  orientation: "left" | "right"
}>`
  display: flex;
  width: 100%;
  justify-content: ${p => (p.hasImage ? "space-between" : "center")};
  text-align: ${p => (p.hasImage ? "left" : "center")};
  flex-direction: ${p => (p.orientation === "right" ? "row-reverse" : "row")};

  ${MobileMediaQuery} {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const Left = styled.div<{
  hasRightComponent: boolean
  orientation: "left" | "right"
}>`
  text-align: ${p => (p.hasRightComponent ? "left" : "center")};
  width: ${p => (p.hasRightComponent ? "580px" : "100%")};

  display: flex;
  flex-direction: column;
  justify-content: ${p => (p.hasRightComponent ? "center" : "space-between")};
  align-items: ${p => (p.hasRightComponent ? "flex-start" : "center")};

  ${p =>
    p.orientation === "right"
      ? css`
          padding-left: 3rem;
        `
      : css`
          padding-right: 3rem;
        `}

  ${MobileMediaQuery} {
    text-align: center;
    margin-bottom: 3rem;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    width: initial;
    max-width: ${p => (p.hasRightComponent ? "580px" : "100%")};
  }
`

const Title = styled(Typography)`
  max-width: 450px;
`

const Markdown = styled(TypographyMarkdown)<{
  isHeadingStyle: boolean
  overrideStyle?: keyof typeof typographyStyles
}>`
  p {
    ${p => {
      if (p.overrideStyle) return typographyStyles[p.overrideStyle]

      return p.isHeadingStyle ? typographyStyles.h6 : typographyStyles.bodyLarge
    }}
  }
  margin-top: 1rem;
  max-width: 450px;
`

const Right = styled.div<{ orientation: "left" | "right" }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${p =>
    p.orientation === "right" ? "flex-start" : "flex-end"};

  ${MobileMediaQuery} {
    width: 100%;
    margin-bottom: 2rem;
    justify-content: center;
  }
`

const Button = styled(HoverButton)`
  width: 330px;
  height: 90px;
  border-radius: 1000px;
  justify-content: center;
  font-weight: 400;
  text-transform: none;
`

interface SideBySideProps extends IWidgetProps<WidgetSideBySide> {
  overrideTitleColor?: TColor
  overrideMarkdownTypography?: keyof typeof typographyStyles
  preamble?: string
}

export const SideBySideWidget = ({
  widget,
  preamble,
  overrideTitleColor,
  overrideMarkdownTypography,
}: SideBySideProps): JSX.Element => {
  const {
    variant,
    orientation,
    title,
    description,
    cta,
    image,
    calendlyUrl,
    illustrationWidget,
  } = widget

  let rightContent = null
  if (image) {
    rightContent = (
      <Img fluid={image.fluid} style={{ flex: 1, borderRadius: 16 }} />
    )
  } else if (calendlyUrl) {
    rightContent = <Calendly url={calendlyUrl} style={{ width: "80%" }} />
  } else if (illustrationWidget) {
    rightContent = <IllustrationWidget widget={illustrationWidget} />
  }

  return (
    <Wrapper
      variant={variant}
      noMinHeight
      style={{
        width: "100vw",
        maxWidth: "100vw",
        borderRadius: 0,
        marginLeft: "-4px",
        marginRight: "-4px",
      }}
    >
      <FullWidthAlignment hasImage={Boolean(image)} orientation={orientation}>
        <Left
          orientation={orientation}
          hasRightComponent={Boolean(
            image || calendlyUrl || illustrationWidget
          )}
        >
          {preamble && (
            <Typography variant="overline" style={{ marginBottom: "2rem" }}>
              {preamble}
            </Typography>
          )}
          <Title
            variant={Boolean(image || illustrationWidget) ? "h3" : "h2"}
            as="h3"
            style={{
              color: overrideTitleColor
                ? colors[overrideTitleColor]
                : variant === "lightpurple" && colors.primary,
            }}
          >
            {title}
          </Title>
          <Markdown
            overrideStyle={overrideMarkdownTypography}
            isHeadingStyle={!Boolean(image) && !Boolean(illustrationWidget)}
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
          {!calendlyUrl && cta && (
            <Link
              to={cta.type === "Internal link" ? `/${cta.url}` : cta.url}
              style={{ textDecoration: "none", marginTop: "4rem" }}
            >
              <Button variant={variant} ghost>
                {cta.text || "Click me"}
              </Button>
            </Link>
          )}
        </Left>
        {rightContent && (
          <Right orientation={orientation}>{rightContent}</Right>
        )}
      </FullWidthAlignment>
    </Wrapper>
  )
}

export const SideBySideFragment = graphql`
  fragment SideBySideWidget on ContentfulSideBySideWidget {
    __typename
    title
    variant
    orientation
    description {
      childMarkdownRemark {
        html
      }
    }
    image {
      fluid {
        base64
        tracedSVG
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
      }
    }
    cta {
      __typename
      ...ctaFragment
    }
    calendlyUrl
    illustrationWidget {
      ...IllustrationWidget
    }
  }
`
