let scriptMounted = null
/* eslint-disable */
export default (clientId, domain, nextGen) => {
  // Remove script from the DOM and reinitialize window.mavenoid (to avoid clashes between current-gen and next-gen assistant)
  if (scriptMounted) {
    scriptMounted.remove()
    window.mavenoid = []
    scriptMounted = null
  }

  // prettier-ignore
  // --- begin mavenoid embedded troubleshooter ---
  ;(function(m, a, v, e, n, o, i, d) {
      d=m.createElement(e);scriptMounted = d;d.async=true;d.src=a+v
      i=m.getElementsByTagName(e)[0];i.parentNode.insertBefore(d,i);n[o]=n[o]||[]
    })(document, domain, "/embedded/embedded.js", "script", window, "mavenoid")
  // --- end mavenoid embedded troubleshooter ---
  window.mavenoid.config = {
    experimental: {
      useNextGenAssistant: nextGen,
    }
  }
  mavenoid.push({
    type: "config",
    config: {
      noIframe: false,
      clientId: clientId,
    },
  })
}
/* eslint-disable */
