import Axios from "axios"
import React from "react"

const iframeOrigin =
  process.env.NODE_ENV === "development" ? "*" : "https://app.mavenoid.com"

const useModelIframeRef = (url: string) => {
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null)
  const messageQueueRef = React.useRef([])
  const modelInitMessageRef = React.useRef(null)
  const modelWasInitialized = React.useRef(false)
  const isCommunicationReadyRef = React.useRef(false)

  // Post a message or queue it
  const postMessage = React.useCallback((message: unknown) => {
    const iframeWindow = iframeRef.current?.contentWindow

    if (iframeWindow && isCommunicationReadyRef.current) {
      iframeWindow.postMessage(message, iframeOrigin)
    } else {
      isCommunicationReadyRef.current = false
      messageQueueRef.current.push(message)
    }
  }, [])

  // Download the model json
  React.useEffect(() => {
    const run = async () => {
      modelInitMessageRef.current = null
      modelWasInitialized.current = false
      const file = await Axios.get(url)
      const { supportModels } = file.data
      const flow = supportModels.find(s => s.kind === "root")
      const flowLib = supportModels.filter(s => s.kind !== "root")
      modelInitMessageRef.current = { type: "mavenoid-reset", flow, flowLib }
      if (!modelWasInitialized.current) {
        modelWasInitialized.current = true
        postMessage(modelInitMessageRef.current)
      }
    }
    run().catch(err => console.error(err))
  }, [url, postMessage])

  // Set up communication channel
  React.useEffect(() => {
    const listener = ({ data }: MessageEvent) => {
      if (data !== "mavenoid-ready") return
      isCommunicationReadyRef.current = true

      if (!modelWasInitialized.current) {
        modelWasInitialized.current = true
        postMessage(modelInitMessageRef.current)
      }

      messageQueueRef.current.forEach(message => {
        postMessage(message)
      })
      messageQueueRef.current = []
    }
    window.addEventListener("message", listener)
    return () => window.removeEventListener("message", listener)
  }, [postMessage])

  const refCallback = React.useCallback((elt: HTMLIFrameElement | null) => {
    iframeRef.current = elt
    if (!elt) {
      modelWasInitialized.current = false
      isCommunicationReadyRef.current = false
    }
  }, [])

  return [refCallback, postMessage] as const
}

export default useModelIframeRef
